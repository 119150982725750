import React from 'react'
import { Link } from 'gatsby'

import MainContainer from '../container/main'
import SEO from '../components/seo'
import Container from '../components/container'
import { notFoundPageContainer } from './404.module.scss'

const NotFoundPage = () => (
  <MainContainer>
    <SEO title="404: Not found" />
    <Container>
      <div className={notFoundPageContainer}>
        <div className="inner-wrapper">
          <div className="message">
            <h1 className="headline">Something went wrong</h1>
            <span className="body">We can't find the page you're looking for.. :-(</span>
          </div>

          <div className="action-container">
            <span className="headline">What do you want to do?</span>
            <Link className="linkBtn primary" to="/">
              Go to Home
            </Link>
            <a href="https://help.basaas.com" className="linkBtn" to="/">
              Visit Help Center
            </a>
            <Link className="linkBtn" to="/contact">
              Contact Basaas
            </Link>
          </div>
        </div>
      </div>
    </Container>
  </MainContainer>
)

export default NotFoundPage
